const Config = {
  datatableFeatures: {
    extraButtons: [
      // {
      //   buttonLabel: 'Download Template',
      //   buttonRef: 'downloadTemplateBtn',
      //   variant: 'info',
      // },
      // {
      //   buttonLabel: 'Upload Bill',
      //   buttonRef: 'openUploadModal',
      //   variant: 'primary',
      // },
    ],
    buttons: {
      editButtonRoute: 'merchant-bill-upload-form',
      viewButtonRoute: 'merchant-bill-upload-form',
      deleteButtonRoute: 'merchant-bill-upload-form',
      createButton: false,
      createButtonLabel: 'Create New Collection',
      createButtonRoute: 'administration-collection-new',
      refresh: true,
    },
    tableActionField: false,
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'title', text: 'Title' },
  ],
  formSchema() {
    return {
      domain: 'string',
      used_link: 'string',
    }
  },
  formData() {
    return {
      title: {
        edit: 'Edit Discount',
        view: 'View Discount',
        remove: 'Remove Discount',
        create: 'Create New Discount',
      },
      form: [],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
        {
          enable: true, param: 'id', btnLabel: 'Download', action: 'download', btnIcon: 'DownloadIcon', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'title', label: 'Title', sortable: true, visible: true,
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'status', label: 'Status', sortable: true, visible: true,
        },
        {
          key: 'record_status', label: 'Record Status', sortable: true, visible: false, type: 'recordStatus',
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: false,
        },
      ],
    }
  },
}

export default Config
