<template>
  <div>
    <b-card>
      <template #header>
        <h4>
          Collection Name: {{ actualForm.title }}
        </h4>
        <b-button-group class="ml-auto">
          <b-button
            size="sm"
            variant="light"
            @click="doCopy(actualForm.uuid, 'UUID')"
          >
            Copy UUID
          </b-button>
          <b-button
            size="sm"
            variant="light"
            @click="doCopy(`${collectionBaseUrl}${actualForm.uuid}`, 'Collection URL')"
          >
            Copy Public URL
          </b-button>
          <b-button
            v-if="adminAuth.view"
            size="sm"
            variant="info"
            @click="viewCollectionURL(actualForm.uuid)"
          >
            Visit Collection Page
          </b-button>
          <b-button
            v-if="adminAuth.edit"
            size="sm"
            variant="primary"
            @click="edit($route.params.id)"
          >
            Configure Collection
          </b-button>
        </b-button-group>
        <b-button
          v-if="adminAuth.edit"
          size="sm"
          class="ml-1"
          :class="[{'btn-danger': actualForm.record_status === 1}, {'btn-warning': actualForm.record_status === 2}]"
          @click="startStopCollection($route.params.id, actualForm.record_status)"
        >
          <template v-if="actualForm.record_status === 1">
            Stop
          </template>
          <template v-if="actualForm.record_status === 2">
            Start
          </template>
          Collection
        </b-button>

      </template>

      <!--      <actual-form-viewer-->
      <!--        raw-data-type="collections"-->
      <!--        :raw-data="actualForm"-->
      <!--      />-->

      <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ actualForm }}</pre>

    </b-card>

    <!--    <bill-upload v-if="isPaymentCollection" />-->
    <company-collection-items-components />
  </div>
</template>

<script>

import actualFormViewer from '@/views/administration/accounts/details/collections/component/actualFormViewer.vue'
import CompanyCollectionItemsComponents
from '@/views/administration/accounts/details/collections/component/companyBillsComponent.vue'

import billUpload from '@/views/administration/accounts/details/collections/component/billUpload/datatable.vue'

const COLLECTIONS_BASE_URL = process.env.VUE_APP_COLLECTIONS_BASE_URL

export default {
  components: { billUpload, CompanyCollectionItemsComponents, actualFormViewer },
  data() {
    return {
      adminAuth: {
        edit: false,
        view: false,
      },
      actualForm: {},
    }
  },
  computed: {
    currentViewAccount() {
      const cva = this.$store.getters['accounts/getCurrentViewAccount']
      return cva
    },
    isPaymentCollection() {
      return this.actualForm.collection_method === 1
    },
    collectionBaseUrl() {
      if (process.env.NODE_ENV === 'development') {
        return '/pay/collections/'
      }
      return COLLECTIONS_BASE_URL
    },
  },
  async mounted() {
    await this.$store.dispatch('collections/getCollectionById', this.$route.params.collectionId).then(res => {
      if (res.data.response_code === 2000) {
        this.actualForm = res.data.data
        const breadcrumbUpdatePayload = [
          { title: 'Accounts', route: 'administration-accounts' },
          { title: 'Accounts Details', route: `/accounts/${this.$route.params.id}/account-details` },
          { title: this.currentViewAccount.account_name },
          { title: 'Collection' },
          { title: `${this.actualForm.title}` },
          { title: 'View Collection' },
        ]

        this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
      }
    })
  },
}
</script>

<style>

</style>
