<template>
  <div>
    <lp-datatable
      ref="CompanyBillsComponentRef"
      query-param="collection_id"
      title="Bills"
      :extra-parameter="extraParams"
      store-module="customerBills"
      store-data-endpoint="getCustomerBillsByCollectionId"
      store-getter-endpoint="customerBillsByCollectionIdList"
      pagination-state-key="customerBillsByCollectionId"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="invoice_ref"
      default-sort-field="created_at"
      :search-options-dropdown="searchFieldOpts"
      :conf="loadedConfig"
      @status-filter-btn-clicked="statusFilterBtnClicked"
      @actionBtn-clicked="actionBtnClicked"
      @aditBtn-clicked="editBtnClicked"
      @viewBtn-clicked="viewBtnClicked"
      @deleteBtn-clicked="deleteBtnClicked"
      @extraBtn-clicked="extraBtnClicked"
    />

  </div>
</template>

<script>
import Config from './companyBillsComponentConfig'
import humaniseMixin from '@/common/humanise.mixin'

import LpDatatable from '@/leanpay-components/LPDataTable.vue'

export default {
  components: { LpDatatable },
  mixins: [humaniseMixin],
  data() {
    return {
      cardTitle: '  Collection',
      dataGridEndpoint: [],
      module: 'collections',

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      record_status: 1, // active only
      searchField: 'name',

      selectedRecordStatus: 2,
      selectedInvoiceStatus: 'pending',

      extraParams: {
        invoice_status: '',
      },

      searchFieldOpts: [
        { value: 'invoice_no', text: 'Invoice No' },
        { value: 'transaction_invoice_no', text: 'Transaction Invoice No' },
        { value: 'phone_number', text: 'Phone Number' },
        { value: 'full_name', text: 'Full Name' },
        { value: 'invoice_ref', text: 'Invoice Ref' },
      ],
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
  },
  async mounted() {

  },
  methods: {
    actionBtnClicked(e) {
      console.log(e)
    },
    statusFilterBtnClicked(e) {
      if (e === 'allBillFilter') {
        this.extraParams.invoice_status = ''
        this.$refs.CompanyBillsComponentRef.initDataGrid()
      }
      if (e === 'pendingBillFilter') {
        this.extraParams.invoice_status = 'pending'
        this.$refs.CompanyBillsComponentRef.initDataGrid()
      }
      if (e === 'successBillFilter') {
        this.extraParams.invoice_status = 'success'
        this.$refs.CompanyBillsComponentRef.initDataGrid()
      }
      if (e === 'failedBillFilter') {
        this.extraParams.invoice_status = 'failed'
        this.$refs.CompanyBillsComponentRef.initDataGrid()
      }
    },
    deleteBtnClicked(e) {
      console.log(e)
    },
    viewBtnClicked(e) {
      console.log(e)
      this.$router.push({
        name: 'administration-accounts-details-collection-bill-info',
        params: {
          invoiceId: e,
        },
      })
    },
    editBtnClicked(e) {
      console.log(e)
    },
    extraBtnClicked(e) {
      console.log(e)
    },
  },
}
</script>
