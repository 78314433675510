<template>
  <div>

    <lp-datatable
      query-param="collection_id"
      title="Bill Uploads"
      store-module="paymentUploadFile"
      store-data-endpoint="getMerchantPaymentUploads"
      store-getter-endpoint="getMerchantPaymentUploads"
      pagination-state-key="merchantPaymentUploads"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="title"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @actionBtn-clicked="actionBtnClicked"
      @aditBtn-clicked="editBtnClicked"
      @viewBtn-clicked="viewBtnClicked"
      @deleteBtn-clicked="deleteBtnClicked"
      @extraBtn-clicked="extraBtnClicked"
    />
    <b-modal
      id="billUploadModal"
      ref="billUploadModal"
      hide-footer
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      title="Bill Upload"
    >
      <upload-form
        @submitclicked="getUploadData"
      />
    </b-modal>
  </div>
</template>

<script>
import Config from '@/views/administration/accounts/details/collections/component/billUpload/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'
import uploadForm from './Form.vue'

export default {
  components: { LpDatatable, uploadForm },
  mixins: [humaniseMixin],
  data() {
    return {

    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
  },
  async mounted() {

  },
  methods: {
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }
      }
    },
    jsonToFormData(data) {
      const formData = new FormData()

      this.buildFormData(formData, data)

      return formData
    },
    downloadItem(href) {
      const link = document.createElement('a')
      link.href = href
      link.download = 'Customer_bill_template.xlsx'
      link.setAttribute('download', 'Customer_bill_template.xlsx') // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      URL.revokeObjectURL(link.href)
    },
    actionBtnClicked(e) {
      console.log(e)
    },
    deleteBtnClicked(e) {
      console.log(e)
    },
    viewBtnClicked(e) {
      console.log(e)
    },
    editBtnClicked(e) {
      console.log(e)
    },
    extraBtnClicked(e) {
      console.log(e)
    },
    doExtra(e) {
      console.log(e)
      if (e === 'downloadTemplateBtn') {
        this.downloadItem('https://lean.sgp1.digitaloceanspaces.com/B41DD9_customer_bill_template.xlsx')

        // alert('do download Template')
      }
      if (e === 'openUploadModal') {
        // alert('do open modal')
        this.$refs.billUploadModal.show()
      }
    },
    getUploadData(e) {
      const payload = {
        ...e,
        collection_id: this.$route.params.id,
      }

      const payload2 = {
        collection_id: this.$route.params.id,
        start_date: this.defaultDateRange.startDate,
        end_date: this.defaultDateRange.endDate,
        limit: 10,
        skip: 0,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: 'created_at',
          sort_type: this.sortDesc ? 'asc' : 'desc',
        },
      }

      this.$store.dispatch('paymentUploadFile/uploadCustomerBillsExcel', this.jsonToFormData(payload))
        .then(res => {
          if (res.data.response_code === 2000) {
            this.$store.dispatch('merchant/getMerchantPaymentUploads', payload2)

            const payload3 = {
              ...payload2,
              id: this.$route.params.id,
              collection_id: this.$route.params.id,
            }
            this.$store.dispatch('merchant/getBillsListByCollectionId', payload3)
            this.$refs.billUploadModal.hide()
          }
        })
    },
  },
}
</script>
