<template>
  <div>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="title"
          rules="required"
        >
          <label>Title </label>
          <b-form-input
            v-model="actualForm.title"
            size="sm"
            :state="errors.length > 0 ? false:null"
            placeholder="Title"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="File"
          rules="required"
        >
          <label>File </label>
          <b-form-file
            v-model="actualForm.file"
            :class="[{'is-invalid': errors.length > 0 }]"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <div class="mt-4">
        <b-button
          type="submit"
          variant="primary"
          @click="handleSubmit(onSubmit)"
        >
          Submit
        </b-button>
        <b-button
          class="ml-1"
          type="reset"
          variant="secondary"
          @click="cancel"
        >Cancel</b-button>
      </div>

    </ValidationObserver>

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      actualForm: {
        title: null,
        file: null,
      },
    }
  },
  computed: {
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
  },
  mounted() {

  },
  methods: {
    onSubmit() {
      this.$emit('submitclicked', this.actualForm)
    },
    cancel() {
      this.$bvModal.hide('billUploadModal')
    },
  },
}
</script>
