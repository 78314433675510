<template>
  <div>
    <template v-if="rawDataType === 'collections'">
      <!--Payment Form-->
      <template v-if="collectionMethod === 1">
        <h5>Collection Information</h5>
        <b-row>
          <b-col cols="7">
            <b-row>
              <b-col cols="4">
                <p>{{ humanise('title') }}</p>
              </b-col>
              <b-col>
                <p v-html="purify(paymentCollectionData.title)" />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                {{ humanise('description') }}
              </b-col>
              <b-col>
                <p v-html="purify(paymentCollectionData.description)" />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <p>{{ humanise('secondary_description') }}</p>
              </b-col>
              <b-col>
                <p v-html="purify(paymentCollectionData.secondary_description)" />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <p>{{ humanise('footer_description') }}</p>
              </b-col>
              <b-col>
                <p v-html="purify(paymentCollectionData.footer_description)" />
              </b-col>
            </b-row>
            <div style="height: 2rem" />
          </b-col>
          <b-col
            offset="1"
            cols="4"
            class="pr-3"
          >
            <h5>Form Configuration</h5>
            <b-row class="rowInfo">
              <b-col>{{ humanise('fixed_amount') }}</b-col>
              <b-col cols="auto">
                <feather-icon
                  size="18"
                  :class="[{'text-danger': !paymentCollectionData.fixed_amount},{'text-success': paymentCollectionData.fixed_amount}]"
                  :icon="!paymentCollectionData.fixed_amount? 'XIcon' : 'CheckIcon'"
                />
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>{{ humanise('enable_quantity_limit') }}</b-col>
              <b-col cols="auto">
                <feather-icon
                  size="18"
                  :class="[{'text-danger': !paymentCollectionData.enable_quantity_limit},{'text-success': paymentCollectionData.enable_quantity_limit}]"
                  :icon="!paymentCollectionData.enable_quantity_limit? 'XIcon' : 'CheckIcon'"
                />
              </b-col>
            </b-row>
            <b-row
              v-if="paymentCollectionData.enable_quantity_limit"
              class="rowInfo"
            >
              <b-col>
                &bull; {{ humanise('Collection Quantity Limit') }}</b-col>
              <b-col cols="auto">
                {{ paymentCollectionData.quantity_limit }}
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>{{ humanise('allow_customers_to_enter_quantity') }}</b-col>
              <b-col cols="auto">
                <feather-icon
                  size="18"
                  :class="[{'text-danger': !paymentCollectionData.allow_customers_to_enter_quantity},{'text-success': paymentCollectionData.allow_customers_to_enter_quantity}]"
                  :icon="!paymentCollectionData.allow_customers_to_enter_quantity? 'XIcon' : 'CheckIcon'"
                />
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>{{ humanise('enable_total_amount_tracking') }}</b-col>
              <b-col cols="auto">
                <feather-icon
                  size="18"
                  :class="[{'text-danger': !paymentCollectionData.enable_total_amount_tracking},{'text-success': paymentCollectionData.enable_total_amount_tracking}]"
                  :icon="!paymentCollectionData.enable_total_amount_tracking? 'XIcon' : 'CheckIcon'"
                />
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>{{ humanise('Require Billing Address') }}</b-col>
              <b-col cols="auto">
                <feather-icon
                  size="18"
                  :class="[{'text-danger': !paymentCollectionData.enable_billing_address},{'text-success': paymentCollectionData.enable_billing_address}]"
                  :icon="!paymentCollectionData.enable_billing_address? 'XIcon' : 'CheckIcon'"
                />
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>{{ humanise('Show seller details on inactive collections') }}</b-col>
              <b-col cols="auto">
                <feather-icon
                  size="18"
                  :class="[{'text-danger': !paymentCollectionData.show_seller_information_enable},{'text-success': paymentCollectionData.show_seller_information_enable}]"
                  :icon="!paymentCollectionData.show_seller_information_enable? 'XIcon' : 'CheckIcon'"
                />
              </b-col>
            </b-row>

            <div style="height: 2rem" />
            <h5>Payment Configuration</h5>
            <b-row class="rowInfo">
              <b-col>
                {{ humanise('min_amount') }}
              </b-col>
              <b-col cols="auto">
                <vue-numeric
                  read-only
                  :precision="2"
                  :currency="myCurrency"
                  separator=","
                  :value="paymentCollectionData.min_amount"
                />
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>
                {{ humanise('total_target_amount') }}
              </b-col>
              <b-col cols="auto">
                <vue-numeric
                  v-if="paymentCollectionData.enable_total_amount_tracking"
                  read-only
                  :precision="2"
                  :currency="myCurrency"
                  separator=","
                  :value="paymentCollectionData.total_target_amount"
                />
                <p
                  v-if="!paymentCollectionData.enable_total_amount_tracking"
                  class="mb-0"
                >
                  Not Applicable
                </p>
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>
                {{ humanise('tax') }}
              </b-col>
              <b-col cols="auto">
                <vue-numeric
                  v-if="paymentCollectionData.tax !== null"
                  read-only
                  :precision="2"
                  separator=","
                  :value="paymentCollectionData.tax"
                />
                <p
                  v-if="paymentCollectionData.tax === null"
                  class="mb-0"
                >
                  Not Applicable
                </p>
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>
                {{ humanise('discount') }}
              </b-col>
              <b-col cols="auto">
                <vue-numeric
                  v-if="paymentCollectionData.discount !== null"
                  read-only
                  :precision="2"
                  separator=","
                  :value="paymentCollectionData.discount"
                />
                <p
                  v-if="paymentCollectionData.discount === null"
                  class="mb-0"
                >
                  Not Applicable
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </template>
      <!--End Payment Form-->

      <!--Bill Form-->

      <template v-if="collectionMethod === 2">
        <h5>Collection Information</h5>
        <b-row>
          <b-col cols="7">
            <b-row>
              <b-col cols="4">
                <p>{{ humanise('title') }}</p>
              </b-col>
              <b-col>
                <p v-html="purify(billCollectionData.title)" />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                {{ humanise('description') }}
              </b-col>
              <b-col>
                <p v-html="purify(billCollectionData.description)" />
              </b-col>
            </b-row>
            <div style="height: 2rem" />

            <h5>Product Information</h5>
            <div>
              <b-table
                striped
                bordered
                responsive
                show-empty
                hover
                :items="billCollectionProducts"
                :fields="billCollectionProductsTableHeader"
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle" />
                    <br>
                    <strong>Loading...</strong>
                  </div>
                </template>

                <template #cell(Product.price)="row">
                  <vue-numeric
                    read-only
                    :precision="2"
                    :currency="myCurrency"
                    separator=","
                    :value="row.item.Product.price"
                  />
                </template>

                <template #cell(Product.title)="row">
                  <h5>{{ row.item.Product.title }}</h5>
                  <div v-html="stripHtml(row.item.Product.description)" />
                  <div
                    v-if="row.item.Product.image.length > 0 "
                    class="py-1"
                  >
                    <template v-for="(image, index) in JSON.parse(row.item.Product.image)">
                      <base-image
                        :key="index"
                        :extra-class="['small-thumb']"
                        :image-public-url="image"
                      />
                    </template>
                  </div>
                </template>

              </b-table>
            </div>
          </b-col>
          <b-col
            offset="1"
            cols="4"
            class="pr-3"
          >
            <h5>Form Configuration</h5>
            <b-row class="rowInfo">
              <b-col>{{ humanise('allow_customers_to_enter_quantity') }}</b-col>
              <b-col cols="auto">
                <feather-icon
                  size="18"
                  :class="[{'text-danger': !billCollectionData.allow_customers_to_enter_quantity},{'text-success': billCollectionData.allow_customers_to_enter_quantity}]"
                  :icon="!billCollectionData.allow_customers_to_enter_quantity? 'XIcon' : 'CheckIcon'"
                />
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>{{ humanise('Require Billing Address') }}</b-col>
              <b-col cols="auto">
                <feather-icon
                  size="18"
                  :class="[{'text-danger': !billCollectionData.enable_billing_address},{'text-success': billCollectionData.enable_billing_address}]"
                  :icon="!billCollectionData.enable_billing_address? 'XIcon' : 'CheckIcon'"
                />
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>{{ humanise('Show seller details on inactive collections') }}</b-col>
              <b-col cols="auto">
                <feather-icon
                  size="18"
                  :class="[{'text-danger': !billCollectionData.show_seller_information_enable},{'text-success': billCollectionData.show_seller_information_enable}]"
                  :icon="!billCollectionData.show_seller_information_enable? 'XIcon' : 'CheckIcon'"
                />
              </b-col>
            </b-row>

            <div style="height: 2rem" />
            <h5>Payment Configuration</h5>
            <b-row class="rowInfo">
              <b-col>
                {{ humanise('tax') }}
              </b-col>
              <b-col cols="auto">
                <vue-numeric
                  v-if="billCollectionData.tax !== null"
                  read-only
                  :precision="2"
                  separator=","
                  :value="billCollectionData.tax"
                />
                <p
                  v-if="billCollectionData.tax === null"
                  class="mb-0"
                >
                  Not Applicable
                </p>
              </b-col>
            </b-row>
            <b-row class="rowInfo">
              <b-col>
                {{ humanise('discount') }}
              </b-col>
              <b-col cols="auto">
                <vue-numeric
                  v-if="billCollectionData.discount !== null"
                  read-only
                  :precision="2"
                  separator=","
                  :value="billCollectionData.discount"
                />
                <p
                  v-if="billCollectionData.discount === null"
                  class="mb-0"
                >
                  Not Applicable
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </template>
      <!--End Bill Form-->

    </template>
    <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">billCollectionProducts: {{ billCollectionProducts }}</pre>
    <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">RAW: {{ rawData }}</pre>
  </div>
</template>

<script>
import DOMPurify from 'isomorphic-dompurify'
import BaseImage from '@/leanpay-components/BaseImage.vue'
import humaniseMixin from '@/common/humanise.mixin'

const truncate = (input, length = 200) => (input.length > length ? `${input.substring(0, length)}...` : input)

export default {
  components: { BaseImage, DOMPurify },
  mixins: [humaniseMixin],
  props: {
    rawData: { type: Object, required: true },
    rawDataType: { type: String, required: true },
  },
  computed: {

    collectionMethod() {
      const collectionMethod = this.rawData.collection_method
      if (collectionMethod) {
        return collectionMethod
      }
      return null
    },
    paymentCollectionData() {
      const paymentCollection = this.rawData
      if (paymentCollection) {
        return {
          id: paymentCollection.id,

          account_id: paymentCollection.account_id,
          record_status: paymentCollection.record_status,
          collection_method: paymentCollection.collection_method,
          fixed_amount: paymentCollection.fixed_amount,
          show_seller_information_enable: paymentCollection.show_seller_information_enable,
          allow_customers_to_enter_quantity: paymentCollection.allow_customers_to_enter_quantity,
          disable_payment_when_target_reached: paymentCollection.disable_payment_when_target_reached,
          enable_total_amount_tracking: paymentCollection.enable_total_amount_tracking,
          enable_quantity_limit: paymentCollection.enable_quantity_limit,
          enable_billing_address: paymentCollection.enable_billing_address,

          discount: paymentCollection.discount,
          custom_field: paymentCollection.custom_field,
          type: paymentCollection.type,
          short_url: paymentCollection.short_url,
          tax: paymentCollection.tax,

          bill_photo: paymentCollection.bill_photo,

          min_amount: paymentCollection.min_amount,
          total_target_amount: paymentCollection.total_target_amount,
          quantity_limit: paymentCollection.quantity_limit,

          share_setting_id: paymentCollection.share_setting_id,
          success_field_id: paymentCollection.success_field_id,
          support_detail_id: paymentCollection.support_detail_id,
          payment_setting_id: paymentCollection.payment_setting_id,

          created_at: paymentCollection.created_at,
          updated_at: paymentCollection.updated_at,
          uuid: paymentCollection.uuid,

          base_url: paymentCollection.base_url,

          title: paymentCollection.title,
          secondary_description: paymentCollection.secondary_description,
          description: paymentCollection.description,
          footer_description: paymentCollection.footer_description,
        }
      }
      return null
    },
    billCollectionProductsTableHeader() {
      const x = [
        {
          key: 'CollectionItem', label: 'Title', sortable: false, visible: false,
        },
        {
          key: 'Product', label: 'Title', sortable: false, visible: false,
        },
        {
          key: 'Product.code', label: 'Code', sortable: false, visible: true,
        },
        {
          key: 'Product.title', label: 'Title', sortable: false, visible: true,
        },
        {
          key: 'Product.quantity', label: 'Qty', sortable: false, visible: true,
        },
        {
          key: 'Product.price', label: 'Price', sortable: false, visible: true,
        },
      ]

      return x.filter(y => y.visible)
    },
    billCollectionProducts() {
      const billCollection = this.rawData

      if (billCollection.product_listing) {
        return billCollection.product_listing
      }
      return 'NOTHINGNESS'
    },
    billCollectionData() {
      const billCollection = this.rawData
      if (billCollection) {
        return {
          id: billCollection.id,

          account_id: billCollection.account_id,
          record_status: billCollection.record_status,
          collection_method: billCollection.collection_method,
          show_seller_information_enable: billCollection.show_seller_information_enable,
          allow_customers_to_enter_quantity: billCollection.allow_customers_to_enter_quantity,
          enable_billing_address: billCollection.enable_billing_address,

          discount: billCollection.discount,
          custom_field: billCollection.custom_field,
          type: billCollection.type,
          short_url: billCollection.short_url,
          tax: billCollection.tax,

          bill_photo: billCollection.bill_photo,

          min_amount: billCollection.min_amount,
          total_target_amount: billCollection.total_target_amount,
          quantity_limit: billCollection.quantity_limit,

          share_setting_id: billCollection.share_setting_id,
          success_field_id: billCollection.success_field_id,
          support_detail_id: billCollection.support_detail_id,
          payment_setting_id: billCollection.payment_setting_id,

          created_at: billCollection.created_at,
          updated_at: billCollection.updated_at,
          uuid: billCollection.uuid,

          base_url: billCollection.base_url,

          title: billCollection.title,
          description: billCollection.description,
        }
      }
      return null
    },
  },
  mounted: {

  },
  methods: {
    stripHtml(str) {
      return this.purify(truncate(str.replaceAll(/(<([^>]+)>)/gi, ''), 200))
    },
  },
}
</script>

<style scoped lang="scss">
.rowInfo {
  line-height: 1.5rem;
  padding: 0.5rem 0;
  &:nth-of-type(odd) { background-color: rgba(#ddd,0.3); }
  &:nth-of-type(even) { background-color:  rgba(#ddd,0.1); }
}
</style>
